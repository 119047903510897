@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');

.navbar {
  width: 100%;
  max-width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .6s ease;
  position: absolute;
  top: 0;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Montserrat";
  font-weight: 500;


  &.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #095c11;
  }

  .logo {
    display: flex;

    img {
      width: 200px;
    }

  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto 0 auto 10px;
    width: 100%;

    ul {
      box-sizing: border-box;
      list-style-type: none;
      display: flex;
      min-height: 6vh;
      justify-content: flex-end;
      align-items: center;

      li {
        text-align: center;
        padding: .5em;
        cursor: pointer;

        &:hover {
          color: #73EF4B;
        }
      }

      li a {
        text-decoration: none;
        color: inherit;
      }

    }
  }
}





