.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .home-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 75%, rgba(9, 92, 17, 1) 100%);

    .logo-block {
      margin: auto;
      width: 80%;
      display: flex;
      flex-direction: column;

      img {
        width: 45%;
        margin: auto;
      }
    }
  }

  .home-content {
    width: 80%;
    background: white;
    height: 300px;
    border-radius: 10px;
    margin: 40px auto 20px auto;
  }

  .home-sub-content {
    width: 80%;
    background: white;
    height: 300px;
    border-radius: 10px;
    margin: 20px auto 20px auto;
  }
}
