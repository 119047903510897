/* Arrow & Hover Animation */
#more-arrows {
  width: 75px;
  height: 65px;
  display: flex;
  margin: 0 auto 10px;


  &:hover {

    polygon {
      fill: #FFF;
      transition: all .2s ease-out;

      &.arrow-bottom {
        transform: translateY(-18px);
      }

      &.arrow-top {
        transform: translateY(18px);
      }

    }

  }

}

polygon {
  fill: #FFF;
  transition: all .2s ease-out;

  &.arrow-middle {
    opacity: 0.75;
  }

  &.arrow-top {
    opacity: 0.5
  }

}
